var app = {
	windowLoaded: function () {
		_main = this;
		time = $('.guest').length?2000:400;
		setTimeout(function(){
			$('.preloader').fadeOut(500, function () {
				// $(this).remove();
			});
		}, time);		
	},
	windowResized: function () {
		_main = this;
		_main.getViewport();
	},
	windowScrolled: function () {
		_main = this;
	},
	cookies: {		
		createCookie: function(name, value, days) {		    
		    _main=app;
		    var date = new Date();
		    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		    var expires = "; expires=" + date.toGMTString();
		    document.cookie = name + "=" + value + expires + "; path=/";
		    (_main.debug)?console.log('creating cookie: ',name):'';
		},
		readCookie: function(name) {
		    _main=app;
		    var nameEQ = name + "=";
		    var ca = document.cookie.split(';');
		    for (var i = 0; i < ca.length; i++) {
		    	var c = ca[i];
		    	while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		    	if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		    }
		    return null;
		},
		checkCookies: function() {
		    _main=app;
		    if (_main.cookies.readCookie(_main.cookieName) != 1) {
		      (_main.debug)?console.log('cookie %s is not created yet',_main.cookieName):'';
		      _main.cookies.notification('show');
		    } else {
		    	(_main.debug)?console.log('cookie %s already exists',_main.cookieName):'';
		    }
		    $('.closeNotification').on('click',function () {
		      _main.cookies.createCookie(_main.cookieName, 1, 365);
		      _main.cookies.notification('hide');
		    });
		},
		notification: function(state) {
			_main=app;
			switch (state) {
				case 'show':
					$('footer').append('<div class="cookiesNotification"><p>Na stronie używamy plików cookies.</p><p class="closeNotification">OK &times;</p></div>');
				break;
				case 'hide':					
					$('.cookiesNotification').fadeOut(300, function () {
				        $(this).remove();
				      });
				break;
			}
		}
	},	
	handleSliders: function() {
		
		if ($('.slider-logos').length) {			
			$('.slider-logos').slick({
				arrows: false,
				dots: false,
				slidesToShow: 4,
				slidesToScroll: 1,
				autoplaySpeed: 500,
				autoplay: true,
				pauseOnFocus: false,
				pauseOnHover: false,
				responsive: [
					{
						breakpoint: 1100,
						settings: {
							slidesToShow: 3,
							vertical: false,
							swipe: true
						}
					}
				]
			});			
		}
		if (_main.viewport == 'mobile' || _main.viewport == 'tablet') {
			if ($('.slider-reviews').length) {			
				$('.slider-reviews').slick({
					arrows: false,
					dots: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplaySpeed: 2500,
					autoplay: true,
					pauseOnFocus: false,
					pauseOnHover: false
				});			
			}
		}
	},	
	events: function () {
		_main = this;
		
		$('.pd').on('click', function(e) {
			e.preventDefault();
		});		

		$('.widget-call .close').on('click', function() {
			$('.widget-call').fadeOut(500, function() {
				$('.widget-call').remove();
			});
		});

		$('.hamburger').on('click', function() {
			$(this).toggleClass('active');	
			$('nav').toggleClass('active');	
			$('body').toggleClass('has-mobile-nav');
		});

		$('.form-toggle').on('click', function() {
			$(this).toggleClass('open');
			$('.form-wrapper').slideToggle();
		});

		var open_pop = setTimeout(function() {
			$('.widget-call').addClass('show');
		},8000);
		
	},
	getViewport: function () {
		_main = this;
		wh = $(window).width();
		if (wh <= _main.breakpointMobile) {
			_main.viewport = 'mobile';
		} else if (wh > _main.breakpointMobile && wh <= _main.breakpointTablet) {
			_main.viewport = 'tablet';
		} else {
			_main.viewport = 'desktop';
		}
		(_main.debug) ? console.log('viewport: ', _main.viewport) : '';		
	},
	init: function () {
		_main = this;
		_main.getViewport();
		_main.events();					
		_main.handleSliders();					
		// _main.handleWaypoints();					
		_main.cookies.checkCookies();
	},

	debug: true,
	viewport: null,
	globalNavState: false,
	breakpointMobile: 640,
	breakpointTablet: 1100,
	cookieName: 'ilscontent'
}

$(document).ready(function () {
	app.init();
});

$(window).on('load', function () {
	app.windowLoaded();
});

$(window).resize(function () {
	app.windowResized();
});

$(document).scroll(function () {
	app.windowScrolled();
});
